<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Banner-3840-x-1390-WA-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.25) 15%, rgba(22,22,22,0.2) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img
                            src="https://cdn.anzcro.com.au/wp-content/uploads/2024/02/drive-the-dream-western-australia-stacked.png"
                            alt="Drive the Dream | Western Australia"
                            class="campaign-title stacked"
                            width="480"
                            height="300"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Roebuck Bay, Broome - Western Australia</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
        <v-container grid-list-lg fill-height>
          <v-layout row wrap fill-height>
            <v-flex xs12 py-0 my-0>
              <v-card tile class="pa-0 elevation-0 deals-content">
                <v-card-text class="pa-5">
                  <v-layout row wrap>
                    <v-flex xs12 md10 py-0 my-0>
                      <span class="headline font-weight-medium kiwiGreen d-block mb-3"
                        >WIN a $2,000* Western Australia Travel Voucher</span
                      >
                      <p>
                        It's easy to understand why Western Australia is a magnet for adventurers,
                        nature lovers, and those seeking to immerse themselves in dreamlike
                        landscapes, endless white sandy beaches, colourful wildflowers, and bright
                        blue skies, but why not prove it for yourself?
                      </p>
                      <p>
                        <b
                          >Sell Western Australia for the chance to experience it yourself, with a
                          $2,000 WA Travel Voucher up for grabs.</b
                        >
                      </p>
                    </v-flex>
                    <v-flex xs12 md2 py-0 my-0 text-xs-center>
                      <a
                        target="_blank"
                        href="https://cdn.anzcro.com.au/wp-content/uploads/2024/05/WA-incentive-Flyer.pdf"
                        ><img
                          src="https://cdn.anzcro.com.au/wp-content/uploads/2024/05/WA-incentive-Flyer.jpg"
                          alt="Win a 7 Day Famil in Western Australia"
                          class="campaign-sticker kirra-early-bird"
                          width="124"
                          height="174"
                      /></a>
                      <small class="kiwiGreen d-block mt-2" style="line-height: 1.2"
                        ><a
                          target="_blank"
                          href="https://cdn.anzcro.com.au/wp-content/uploads/2024/05/WA-incentive-Flyer.pdf"
                          ><b>Learn How to Win</b></a
                        ></small
                      >
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex>
            <!--              
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 px-5 pb-5 elevation-0">
                    <v-responsive :aspect-ratio="16/9">
                      <div style="padding:56.25% 0 0 0;position:relative;"><iframe style="position:absolute;top:0;left:0;width:100%;height:100%;" src="https://www.youtube.com/embed/elY6x0tTGFo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                    </v-responsive>
                  </v-card>                
                </v-flex>
                -->
            <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
              <v-card tile dark class="pa-0 ma-0 elevation-0">
                <v-card-text class="px-3 py-2">
                  <span class="headline font-weight-medium d-block">{{ section.title }}</span>
                </v-card-text>
              </v-card>
              <v-card tile class="px-3 py-3 elevation-0">
                <v-layout row wrap ma-0>
                  <v-flex xs12 pa-0 hidden-sm-and-down>
                    <v-layout row wrap my-0>
                      <v-flex xs12 md7 lg8>
                        <span class="caption grey--text">Package Deal</span>
                      </v-flex>
                      <v-flex xs12 md3 lg2 text-md-right>
                        <span class="caption grey--text">From Price (NZD)</span>
                      </v-flex>
                      <v-flex xs12 md2 lg2 text-md-right>
                        <span class="caption grey--text">Inclusions & Terms</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                    <v-divider></v-divider>
                    <v-layout row wrap align-center my-3>
                      <v-flex xs12 md7 lg8>
                        <v-layout row align-center>
                          <v-flex shrink hidden-md-and-down>
                            <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                          </v-flex>
                          <v-flex>
                            <span class="title d-block font-weight-medium mb-2">{{
                              deal.name
                            }}</span>
                            <span class="caption d-block mb-1 grey--text"
                              ><b>Dates:</b> {{ deal.valid }}</span
                            >
                            <span class="d-block package-data">
                              <v-chip label color="#f5f5f5" disabled class="mb-0">
                                <b>Start:</b>&nbsp;<span class="package-start-location">{{
                                  deal.location
                                }}</span>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      small
                                      icon
                                      v-on="on"
                                      class="mr-0"
                                      v-on:click="copyLocation"
                                      ><v-icon size="14" :color="kiwiGreen"
                                        >content_copy</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Copy Start location</span>
                                </v-tooltip>
                              </v-chip>
                              <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                <b>Code:</b>&nbsp;<span class="package-code">{{ deal.code }}</span>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"
                                      ><v-icon size="14" :color="kiwiGreen"
                                        >content_copy</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Copy Package Code</span>
                                </v-tooltip>
                              </v-chip>
                              <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                <b>Call ANZCRO to Book</b
                                ><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                              </v-chip>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm6 md3 lg2 text-md-right>
                        <span class="headline d-block font-weight-medium mb-2 kiwiGreen"
                          >${{ parseInt(deal.price).toLocaleString() }}<sup>*</sup></span
                        >
                        <span class="caption d-block grey--text">{{ deal.complement }}</span>
                      </v-flex>
                      <v-flex xs12 sm6 md2 lg2 text-sm-right>
                        <v-btn
                          large
                          class="card-with-border elevation-0 mx-0 px-2"
                          :color="lightGrey"
                          :href="deal.itinerary"
                          target="_blank"
                          ><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500"
      >{{ copiedText }} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar
    >
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: "",
    sections: [
      {
        title: "Self-Drive Deals",
        deals: [
          {
            thumbnail: "https://cdn.anzcro.com.au/wp-content/uploads/2024/03/deal-wa-taste-sw.jpg",
            name: "7 Day Taste of the South West",
            price: "1079",
            complement: "per person (twin share)",
            code: "WA247DTSW",
            location: "Perth (PER)",
            valid: "04 May - 25 Nov 2024",
            itinerary:
              "https://cdn.anzcro.com.au/wp-content/uploads/2024/03/7-Day-Taste-of-the-South-West-NZD.pdf",
            call: false,
          },
          {
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2024/03/deal-wa-swe-road-trip.jpg",
            name: "10 Day South West Edge Road Trip",
            price: "1639",
            complement: "per person (twin share)",
            code: "WA2410DSE",
            location: "Perth (PER)",
            valid: "03 May - 30 Nov 2024",
            itinerary:
              "https://cdn.anzcro.com.au/wp-content/uploads/2024/03/10-Day-The-South-West-Edge-Road-Trip-NZD.pdf",
            call: false,
          },
        ],
      },
      {
        title: "Perth & Beyond",
        deals: [
          {
            thumbnail: "https://cdn.anzcro.com.au/wp-content/uploads/2024/03/deal-wa-pinnacles.jpg",
            name: "5 Day Perth Culture, Coffee & Pinnacles",
            price: "789",
            complement: "per person (twin share)",
            code: "WA244DPCP",
            location: "Perth (PER)",
            valid: "08 Apr - 29 Nov 2024",
            itinerary:
              "https://cdn.anzcro.com.au/wp-content/uploads/2024/03/5-Day-Perth-Culture-Coffee-The-Pinaccles-NZD.pdf",
            call: false,
          },
          {
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2024/03/deal-wa-perth-beyond.jpg",
            name: "4 Day Perth, Rottnest Island & Beyond",
            price: "859",
            complement: "per person (twin share)",
            code: "WA244DPRIAB",
            location: "Perth (PER)",
            valid: "01 May - 30 Nov 2024",
            itinerary:
              "https://cdn.anzcro.com.au/wp-content/uploads/2024/03/4-Day-Perth-Rottnest-Island-Beyond-NZD.pdf",
            call: false,
          },
        ],
      },
    ],
  }),
  mounted: function () {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if (event) {
        let copyCode = event.target.closest(".v-chip__content").querySelector(".package-code");
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = "Package code copied to clipboard";
        this.copied = true;
      }
    },
    copyLocation(event) {
      if (event) {
        let copyLocation = event.target
          .closest(".v-chip__content")
          .querySelector(".package-start-location");
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = "Package start location copied to clipboard";
        this.copied = true;
      }
    },
  },
};
</script>

<style>
.gradient-bar {
  width: 100%;
  height: 9px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#65b32e),
    color-stop(#d2d2d2),
    to(#30302f)
  );
  background: linear-gradient(90deg, #65b32e, #d2d2d2, #30302f);
}

.deals-content {
  margin-top: -60px;
}

.package-data .v-chip {
  color: #9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content {
  padding-right: 6px;
}

.deal-thumbnail {
  width: 88px;
  height: 88px;
}
</style>
